const postOptions = {
  method: 'POST',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json;charset=UTF-8',
  },
};

export async function fetcher(url: string): Promise<string> {
  const res = await fetch(url);
  return res.json();
}

export default postOptions;
